
import "./AddButton.css"
import { IoMdAddCircleOutline, IoMdAdd } from 'react-icons/io';

function AddButton({ callback }) {
    
    return (
        <div className="Add-plus-btn" onClick={callback}>
            <span><IoMdAdd size="1.5em"/></span>
        </div>
    );
}

export default AddButton;