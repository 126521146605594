

import React, { useEffect, useState } from 'react'
import CompanyService from '../../../services/CompanyService';

import { useHistory } from 'react-router-dom';
import ScheduleModal from '../../../components/modals/schedule/ScheduleModal';
import CreateClientModal from '../../../components/modals/client/CreateClientModal'
import AddButton from '../../../components/addbtn/AddButton'
import { TbChevronsRight, TbChevronsLeft, TbSearch } from 'react-icons/tb';

export default function Clients() {

    const history = useHistory();

    const [showClientModal, setShowClientModal] = useState(false);

    const closeCompanyModal = () => {
        setShowClientModal(false);
        setCompanyService(CompanyService.instance);
    }

    // const companyService = CompanyService.instance;
    const [companyService, setCompanyService] = useState(CompanyService.instance);

    const [filter, setFilter] = useState({
        limit: 5,
        page: 1,
        name: undefined
    });

    const [clients, setClients] = useState({
        docs: [
            {
                name: "Company",
                representative: "Jon Doe",
                hours: 10
            },
            {
                name: "Company",
                representative: "Jon Doe",
                hours: 10
            },
            {
                name: "Company",
                representative: "Jon Doe",
                hours: 10
            },
            {
                name: "Company",
                representative: "Jon Doe",
                hours: 10
            },
        ],
        page: 1,
        limit: 10,
        totalDocs: 4,
        totalPages: 1,
    });

    async function fetchClients() {
        const res = await companyService.getCompanies(filter);
        setClients(res);
    }

    useEffect(() => fetchClients(), [companyService, filter]);

    const viewClient = (clientId) => {
        history.push(`/clients/${clientId}`)
    }

    return (
        <div>
             <h4>List of Your Clients</h4>
                    <div className="underline"></div>
                <div className="space">_</div>
            <div className="Info-card">
                <div>

                    <div>
                        <TbSearch size="1.8em" color="#545454" />
                        <input
                            placeholder='Search clients'
                            className='Form-control1'
                            onChange={e => setFilter({ ...filter, name: e.target.value })}
                        />
                    </div>

                    <table class="Table Striped-table">

                        <thead>
                            <tr>
                                <th>Company Name</th>
                                <th>Representative</th>
                                <th>Hours per Month</th>
                            </tr>
                        </thead>

                        <tbody>
                            {!clients.docs ? null : clients.docs.map(client => {
                                return (
                                    <tr onClick={() => viewClient(client._id)}>
                                        <td>{client.name}</td>
                                        <td>{client.representative ? client.representative.email : "unknown"}</td>
                                        <td>{client.hours ? client.hours : 'N/A'}</td>
                                    </tr>
                                )
                            })}
                        </tbody>

                    </table>
                </div>
            </div>


            {showClientModal ? <CreateClientModal show={showClientModal} close={closeCompanyModal} /> : null}
            <div className="Info-card-bottom-nav">
                <AddButton callback={() => setShowClientModal(true)} />

                <div className='Page-counter'>
                    <button className="sidebutton" disabled={filter.page <= 1} onClick={() => setFilter({ ...filter, page: filter.page - 1 })}>
                        <TbChevronsLeft size="2em" /></button>

                    Page {filter.page} of {clients.totalPages}

                    <button className="sidebutton" disabled={filter.page >= clients.totalPages} onClick={() => setFilter({ ...filter, page: filter.page + 1 })}>
                        <TbChevronsRight size="2em" /> </button>
                </div>
            </div>
        </div>
    );
}
