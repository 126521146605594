import { config } from 'dotenv';

let serverUrl;

if (process.env.CUSTOM_NODE_ENV === 'development'){
    config('../.env.local');
    serverUrl = 'http://localhost:8080';
}
else {
    console.log("PROD")
    config('../.env.prod')
    serverUrl = 'https://portal.aevis.de';
    // serverUrl = 'https://www.sitesnipe.io'

}

const appConf = {
    SERVER_URL: serverUrl
}

export default appConf;