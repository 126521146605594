import moment from "moment";
import { useState } from "react";
import ComplaintModal from "../../modals/complaint/ComplaintModal";

import "./SingleEvent.css";

function SingleEvent(props) {

    const { 
        _id,
        dateStart, 
        timeFrom,
        employee,
        timeTo, 
        events,
        tasks,

        dayFullName,
    } = props;

    const [showComplaintModal, setShowComplaintModal] = useState(false);

    const headingId = `event_heading_${_id}`
    const collapseId = `single_event_${_id}`

    let currentEvent = events[0];


    const hasEventPassed = () => {
        
        if (events.length === 0)
            return false;

        const endOfEvent = moment(currentEvent.date)
        
        endOfEvent.set('h', +timeTo.split(":")[0]).set('m', +timeTo.split(":")[1]);

        let now = moment()
        // console.log("End", endOfEvent.toString())
        // console.log("Now", now.toString())
        // console.log(endOfEvent.isBefore(now))

        return endOfEvent.isBefore(now);
    }

    console.log('Event', _id)
    console.log("Has event passed?", hasEventPassed())
    console.log(showComplaintModal, currentEvent)
    // console.log(events[0].tasks)

    // const day = moment(dateStart).format('dddd');

    return (
        <div className="accordion mt-3">
            <div className="accordion-item">
                <h2 className="accordion-header" id={headingId}>
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#" + collapseId} aria-expanded="false" aria-controls={collapseId}>
                        <div className="Day-section">
                            {dayFullName}
                        </div>
                        {timeFrom + "-" + timeTo}
                    </button>
                </h2>
                <div id={collapseId} className="accordion-collapse collapse" aria-labelledby={headingId} data-bs-parent="#accordionExample">
                    <div className="accordion-body row">
                        {
                            currentEvent && currentEvent.employee?
                                <div className="col-6 Assignee-info">
                                    <p>{currentEvent.employee.first_name + " " + currentEvent.employee.last_name}</p>
                                    <p className="Asignee-info__email">{currentEvent.employee.email}</p>
                                </div>
                                : (employee ? <div className="col-6 Assignee-info">
                                    <p className="Asignee-info__user">{employee.first_name + " " + employee.last_name}</p>
                                    <p className="Asignee-info__email">{employee.email}</p>
                                </div> : null)
                        }
                        <div className="col-6">
                            {  
                                currentEvent ? 
                                    Object.entries(currentEvent.tasks).map(([x, v]) => {
                                        return <div className="Task-list" key={x}>
                                             <div className={"Task-item " + (v ? 'complete' : 'notcomplete')}>
                                                    {x}
                                            </div>
                                        </div>
                                    
                                    })
                                    : tasks.map(t => {
                                        return  (
                                            <div className="Task-list" key={t}>
                                                <div className="Task-item notcomplete">
                                                    {t}
                                                </div>
                                            </div>)
                                    })
                            }
                        </div>
                       
                        
                        {
                            hasEventPassed()  ?
                            <div className="col-12 d-flex justify-content-center">
                                <div className="col-4" >
                                    {/* ovde je bio <h5>Submit Complaint ili sta god</h5> */}
                                    <button className="complaint_button" onClick={() => setShowComplaintModal(true)}>Submit A Complaint</button>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>

            { showComplaintModal && currentEvent ? 
                <ComplaintModal 
                    show={showComplaintModal}
                    scheduleEvent={currentEvent._id} 
                    scheduleEntry={_id} 
                    close={() => setShowComplaintModal(false)}/> 
                : null}
            {/* { showComplaintModal ? <ComplaintModal scheduleEvent={currentEvent._id} scheduleEntry={_id} close={() => setShowComplaintModal(false)}/> : null} */}

        </div>

    );
}

export default SingleEvent;