import React, { useEffect, useState } from "react";

import EmployeeService from "../../../services/EmployeeService";
import AuthService from "../../../services/AuthService";

import AddButton from "../../../components/addbtn/AddButton";
import { TbChevronsRight, TbChevronsLeft, TbSearch } from "react-icons/tb";
import CreateEmployeeModal from "../../../components/modals/employee/CreateEmployeeModal";

export default function Employees() {
  const employeeService = EmployeeService.instance;

  const [showEmployeeModal, setShowEmployeeModal] = useState(false);

  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
    name: undefined,
    email: undefined,
  });

  const [employees, setEmployees] = useState({});

  const fetchEmployees = async () => {
    const res = await employeeService.getEmployees(filter);

    setEmployees(res);
  };

  useEffect(() => {
    fetchEmployees();
  }, [employeeService, filter]);

  return (
    <div>
      <h4>List of Your Employees</h4>
          <div className="underline"></div>
        <div className="space">_</div>
      <div className="Info-card">
          
        <div>
          <div>
            <TbSearch size="1.8em" color="#545454" />
            <input
              onChange={(e) => setFilter({ ...filter, name: e.target.value })}
              placeholder="Search employees"
              className="Form-control1"
            />
          </div>

          <table class="Table Striped-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Hours per Month</th>
              </tr>
            </thead>

            <tbody>
              {!employees.docs
                ? null
                : employees.docs.map((employee) => {
                    return (
                      <tr key={employee._id}>
                        <td>
                          {employee.first_name + " " + employee.last_name}
                        </td>
                        <td>{employee ? employee.email : "unknown"}</td>
                        <td>{employee.hours ? employee.hours : "N/A"}</td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      </div>


      {showEmployeeModal ? (<CreateEmployeeModal show={showEmployeeModal} close={() => setShowEmployeeModal(false)} />) : null}
        <div className="Info-card-bottom-nav">
            <AddButton callback={() => setShowEmployeeModal(true)} />

        <div className="Page-counter">
            <button class="sidebutton" disabled={filter.page <= 1} onClick={() => setFilter({ ...filter, page: filter.page - 1 })}>
            <TbChevronsLeft size="2em" /> </button>

            Page {filter.page} of {employees.totalPages}
          
            <button class="sidebutton" disabled={filter.page >= employees.totalPages} onClick={() => setFilter({ ...filter, page: filter.page + 1 })}>
            <TbChevronsRight size="2em" /></button>
        </div>
      </div>
    </div>
  );
}
