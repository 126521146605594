
import React from 'react'

import { Switch, Route, Redirect } from 'react-router-dom'

import Sidebar from '../../parts/sidebar/Sidebar'
import Dashboard from './dashboard/Dashboard'
import Profile from './profile/Profile'

import AuthService from '../../services/AuthService'
import Navbar from '../../parts/navbar/Navbar'
import Employees from './emplyees/Employees'
import Clients from './clients/Clients'

import "./Home.css";
import Client from './clients/client/Client'
import RoleProtectedRoute from '../../helpers/RoleProtectedRoute'
import {ErrorBoundary} from 'react-error-boundary'

function ErrorFallback({error, resetErrorBoundary}) {

    return (
        <>  
           
            <Navbar/>
            <div role="alert" className='error'>
                <p className='error-title'>Oops, something went  wrong. Please try again.</p>
                <pre className='error-message'>{error.message}</pre>
                <button onClick={resetErrorBoundary} className='confirmation_button'>Try again</button>
            </div>
        </>
      )
  }

export default function Home() {

    const authService = AuthService.instance;

    return (
        authService.isLoggedIn() ? 
            <div>
                <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                 >
                <Navbar />
                <Sidebar/>
                <div className="Main-content-area">
                    <Switch>
                        <Route path="/dashboard">
                            <Dashboard />
                        </Route>
                        <RoleProtectedRoute exact path="/clients" role="admin">
                            <Clients />
                        </RoleProtectedRoute>
                        <Route exact path="/clients/:id"
                            render={({match}) => {
                                return <Client company_id={match.params.id}/>
                            }}>
                        </Route>
                        {/* <RoleProtectedRoute exact path="/clients">
                            <Clients />
                        </RoleProtectedRoute> */}
                        <RoleProtectedRoute exact path="/employees" role="admin">
                            <Employees />
                        </RoleProtectedRoute>
                        <Route path="/profile">
                            <Profile />
                        </Route>
                    </Switch>
                </div>
                </ErrorBoundary>
            </div>
        : <Redirect to="/login" />
    )
}
