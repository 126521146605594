
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react/cjs/react.production.min";
import CompanyService from "../../../services/CompanyService";
import EmployeeService from "../../../services/EmployeeService";
import GenericModal from "../GenericModal";
import { toast } from 'react-toastify';
import "./ScheduleModal.css";

function ScheduleModal({ company_id, address, ...otherProps }) {
   


    const companyService = CompanyService.instance;
    const employeeService = EmployeeService.instance;

    const {
        close
    } = otherProps;

    const defaultState = {
        dateStart: moment().toDate(),
        timeFrom: "08:00",
        timeTo: "09:00",
        recurrence: "SINGLE",
        day: "MO",
        nthOfTheMonth: 1,
        tasks: [],
        employeeId: undefined,
    }

    const [newScheduleEntry, setNewScheduleEntry] = useState(defaultState);

    const [availableEmployees, setAvailableEmployees] = useState([]);

    // useEffect(() => {}, [availableEmployees])

    const handleChange = async (evt) => {

        console.log("Handling change")

        let scheduleEntryUpdate = {
            ...newScheduleEntry,
            [evt.target.name]: evt.target.value
        }

        if (evt.target.name != 'employeeId'){
            const newEmployeeList = await employeeService.getAvailableEmployees(scheduleEntryUpdate);
            
            console.log("Received new employees: ", newEmployeeList)

            setAvailableEmployees(newEmployeeList);

            console.log("New availalbe employees", availableEmployees)

            if (!newEmployeeList.map(e => e._id).includes(newScheduleEntry.employeeId)){
                newScheduleEntry.employeeId = undefined;
            }

            // if ((!newScheduleEntry.employeeId && !availableEmployees.map(e => e._id).includes(newScheduleEntry.employeeId))
            //     || newEmployeeList.length === 0){
            //     // setNewScheduleEntry({
            //     //     ...newScheduleEntry,
            //     //     employeeId: undefined
            //     // })
            //     scheduleEntryUpdate.employeeId = undefined;
            // }
            // else {
            //     scheduleEntryUpdate.employeeId = newEmployeeList[0].id;
            // }
        }

        setNewScheduleEntry(scheduleEntryUpdate);

        
    }

    const handleTaskInput = (evt) => {
        
        if (evt.key === 'Enter'){

            const task = evt.target.value;

            if (!task)
                return

            let newTasks = newScheduleEntry.tasks;
            newTasks.push(task);

            setNewScheduleEntry({
                ...newScheduleEntry,
                tasks: newTasks
            });

            evt.target.value = "";

        }

    }

    const handleTaskRemoval = (evt) => {
        const task = evt.target.id;

        setNewScheduleEntry({
            ...newScheduleEntry,
            tasks: newScheduleEntry.tasks.filter(t => t !== task)
        })
    }
    
    const submitForm = async () => {
        try {
            await companyService.submitCompanyScheduleEntry(company_id, address, newScheduleEntry);
    
            close();
        } catch (err){
            toast.error(err.message)
        }
    }

    return (  
        <GenericModal { ...otherProps}>
            <h3 id="modal-title">Create new Schedule Entry!</h3>
            <div className="underline-title"></div>
            <div className="space">_</div>

            <select name="recurrence" onChange={handleChange} className="field" value={newScheduleEntry.recurrence}>
                <option value={"SINGLE"}>One time event</option>
                <option value={"WEEKLY"}>Weekly event</option>
                <option value={"MONTHLY"}>Monthly event</option>
            </select>
            <br/>
            <div className="form-border"></div>

            <div className="space">_</div>

            <input type="date" name="dateStart" className="date_widget" value={newScheduleEntry.dateStart} onChange={handleChange}/>
            <div className="form-border"></div>
           
            <div className="space">_</div>

            <div className="wrapper">

            {
                newScheduleEntry.recurrence === "MONTHLY" ?
                    <div>
                        Every: 
                        <select id="picker1" name="nthOfTheMonth" value={newScheduleEntry.nthOfTheMonth} onChange={handleChange}>
                            <option value={1}>1st</option>
                            <option value={2}>2nd</option>
                            <option value={3}>3rd</option>
                            <option value={4}>4th</option>
                        </select>
                    </div>
                    : null
            }
    
            {
                newScheduleEntry.recurrence !== "SINGLE" ?
                    <div> 
                        <select id="picker2" name="day" value={newScheduleEntry.day} onChange={handleChange}>
                            <option value={"MO"}>Monday</option>
                            <option value={"TU"}>Tuesday</option>
                            <option value={"WE"}>Wednesday</option>
                            <option value={"TH"}>Thursday</option>
                            <option value={"FR"}>Friday</option>
                            <option value={"SA"}>Saturday</option>
                            <option value={"SU"}>Sunday</option>
                        </select>
                    </div>
                    : null  
            }  
            
            </div>

            <br/>
            🕑 From: <input name="timeFrom" className="time_widget" type="time" step={900} min={"08:00"} value={newScheduleEntry.timeFrom} onChange={handleChange}/>
            &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;To: <input name="timeTo" className="time_widget" type="time" value={newScheduleEntry.timeTo} onChange={handleChange}/>
            
            

            <div className="space">_</div>

            <div className="Task-input">
                {
                    newScheduleEntry.tasks.map(task => {
                        return (<div className="Task-tag" onClick={handleTaskRemoval} id={task}>{task} <span></span></div>)
                    })
                }
                <input className="Input-field" onKeyDown={handleTaskInput} placeholder="Enter your tasks here... "></input>
            </div>
            <br/>
            
            
            <select name="employeeId" className="employee_widget" value={newScheduleEntry.employeeId} onChange={handleChange}>
                {
                    availableEmployees.length > 0 ? 
                        [
                            <option value={undefined}>No employee selected</option>  
                        ].concat(availableEmployees.map(el => {
                            return (<option value={el._id}>{el.email}</option>)
                        }))
                        : <option value={undefined}>No employee available</option>
                }
            </select>
            <div className="form-border"></div>
            <div className="space">_</div>
            <div className="space">_</div>

            <div id="button_centering">
                <button id="modal_button" 
                onClick={submitForm} className="btn btn-primary btn-lg btn-block"
                type="submit"><span>Create!</span></button>
            </div>
        </GenericModal>
    );
}

export default ScheduleModal;