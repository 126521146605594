import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router } from "react-router-dom";

import AuthService from './services/AuthService';

import "./config";

const originalFetch = window.fetch
const authService = AuthService.instance;

console.log("OVerriding fetch")
window.fetch = async (url, opts) => {

  const token = authService.getToken();

  opts = opts ? opts : {};

  // opts.headers['authorization'] = `Bearer ${token}`
  if (opts.headers){
    opts.headers['authorization'] = `Bearer ${token}`
  }
  else {
    opts.headers = {
      'authorization': `Bearer ${token}`
    }
  }
  

  const response = await originalFetch(url, opts);
  
  return response;
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
