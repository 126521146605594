
import React, { useState } from 'react'
import AuthService from '../../../services/AuthService'

import { TbUserCircle, TbPhoneCall, TbMail, TbMap2 } from "react-icons/tb";
export default function Profile() {

    const authService = AuthService.instance;

    const user = authService.getLoggedInUser();

    const [passwordForm, setPasswordForm] = useState({
        first: "",
        second: "",
    });

    const validatePasswordForm = (evt) => {
        evt.preventDefault();

        // TODO: Add more validation and error messages

        return passwordForm.first !== ""
            && passwordForm.second !== ""
            && passwordForm.first === passwordForm.second; 
    }

    const onResetPassword = () => {
        //  authService.resetPassword(passwordForm);
        authService.changePassword(passwordForm.first)
    }
    return (
        <div>
            <h4>My Account</h4>
                        <div className="underline"></div>

            <div className="row mt-3">
                <div className="col-12 mt-3 col-lg-6">
                    <div className='Info-card'>
                        <p><TbUserCircle size="1.7em" color="#545454" /> &nbsp;  <b>{user.first_name + " " + user.last_name}</b></p>
                        <hr />
                        <p><TbMail size="1.7em" color="#545454" /> &nbsp;  <b>{user.email}</b></p>
                        <hr />
                        <p><TbPhoneCall size="1.7em" color="#545454" /> &nbsp; <b>+69 420 6969 </b></p>
                        <hr />
                        <p><TbMap2 size="1.7em" color="#545454" /> &nbsp;  <b>Address </b></p>
                    </div>
                </div>
                <div className="col-12 mt-3 col-lg-6">
                    <div className='Info-card'>
                        <h4>Additional Data that might be useful</h4>
                        <ul>
                            <li>✅</li>   
                            <li>❌</li>   
                            <li>⚠️</li>   
                        </ul>
                    </div>
                </div>
                
            </div>


            <div className="row mt-3">
                <div className="col-lg-6 ">
                    <div className="Info-card">
                        <h4>Change Password</h4>
                        <form onSubmit={validatePasswordForm}>
                            
                            <input 
                                type="password" className="form-content2"
                                placeholder='Password'
                                value={passwordForm.first}
                                onChange={evt => setPasswordForm({ ...passwordForm, first: evt.target.value})}/>
                                
                            <div className="space">_</div>

                            <input 
                                type="password" className="form-content2"
                                placeholder='Retype password'
                                value={passwordForm.second}
                                onChange={evt => setPasswordForm({ ...passwordForm, second: evt.target.value})}/>
                            <div className="space">_</div>
                            
                            <button className="confirmation_button" type='submit' onClick={onResetPassword}>Confirm
                            </button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    )
}
