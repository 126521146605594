
import React, { useState, useEffect } from 'react';
import AuthService from '../../../services/AuthService';
import DashboardService from '../../../services/DashboardService';
import AdminDashboard from './admin/AdminDashboard';
import ClientDashboard from './client/ClientDashboard';

import "./Dashboard.css";

export default function Dashboard() {
    const authService = AuthService.instance;
    
    return (
        authService.userHasRole("admin") ? 
            <AdminDashboard /> : 
            <ClientDashboard />   
    )
}
