import config from "../config";

export default class EmployeeService {

    static instance = this.instance || new EmployeeService();

    async getEmployees(filter) {

        const url = new URL(`${config.SERVER_URL}/api/employee`)


        if (filter){
            const searchParams = new URLSearchParams(
                Object.fromEntries(
                    Object.entries(filter).filter(([_, v]) => v)
                )
            )
    
            url.search = searchParams.toString();
    
        }

        let res = await fetch(url);

        res = await res.json();

        return res;
    }

    async createEmployee(payload) {

        const url = new URL(`${config.SERVER_URL}/api/employee`)

        const res = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload)
        });

        return await res.json();

    }

    async getAvailableEmployees(payload) {

        const url = new URL('/api/employee/available', `${config.SERVER_URL}`)

        const res = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        });

        return await res.json();
    }


}