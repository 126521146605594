
import { useHistory } from "react-router-dom";
import "./TopNList.css"

function TopNList({ topN, entityName }) {

    const history = useHistory();

    return (
        <>
            <div className="Top-N-Header">
                <h4>Top 3 {entityName}</h4>
                <div className="underline"></div>
            </div>
            <div className="space">_</div>

            <table className="Top-N-Table">
                <tbody>

                    {
                        topN?.map((entity, ind) => {
                            return (
                                <tr 
                                    onClick={() => {
                                        if (entityName === 'Companies'){
                                            history.push(`/clients/${entity.id}`)
                                        }  
                                    }}
                                    className={entityName === 'Companies' ? 'Clickable-tr' : ''} 
                                    key={`top_n_emp_${(ind + 1)}`}>
                                    <td>{"#" + (ind + 1)}</td>
                                    <td>{entity.name}</td>
                                    <td>{"~" + entity.sumDuration + " hrs/month"}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>

            </table>

        </>
    );
}

export default TopNList;