import React, { useState, useEffect } from 'react';
import WeeklySchedule from '../../../../components/weeklySchedule/WeeklySchedule';

import AuthService from '../../../../services/AuthService';

function ClientDashboard() {

    const authService = AuthService.instance;

    const [user, setUser] = useState({});
    useEffect(() => setUser(authService.getLoggedInUser()), [authService])

    return (
        <div>

            <div className="row mt-3">
                <div className="col-lg-6 Welcome-message">
                    <h2>Hello {user.first_name},</h2>
                    <div className="underline_dashboard"></div>
                    <div className="space">_</div>
                    <h5>Here is your weekly schedule</h5>
                </div>
            </div>

            <WeeklySchedule />

        </div>
    );
}

export default ClientDashboard;