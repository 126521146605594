import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js'
import { Bar } from "react-chartjs-2";

function NextNDaysChart({ apiData }) {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    )

    const {
        hrs,
        dates
    } = apiData;

    const data = {
        labels: dates,
        datasets: [
            {
                data: hrs,
                backgroundColor: 'rgb(234, 115, 23, 0.6)',
                label: 'Hours'
            }
        ]
    }

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Chart.js Bar Chart',
          },
        },
      };

    return (
        <>
            <Bar 
                options={options}
                data={data}/>
        </>
    );
}

export default NextNDaysChart;