
import './App.css';
import { 
  Switch, 
  Route,
  Redirect,
} from "react-router-dom"

import Login from './pages/login/Login';
import Home from './pages/home/Home';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap"

function App() {

  return (
    <div className="App">
      
      <Switch>
        <Redirect exact from='/' to='/dashboard'/>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/">
          <Home />
        </Route>

      </Switch>
    </div>
  );
}

export default App;
