
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import "./Login.css"

import AuthService from '../../services/AuthService';

export default function Login() {

    const authService = AuthService.instance;

    const history = useHistory();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [badCreds, setBadCreds] = useState(false);

    const sendLogInInfo = async () => {

        const success = await authService.login(email, password);

        success ? history.push("/") : setBadCreds(true);

    };

    //login uz pomoc entera 
    
    const enterLogin = (e) => {
        if (e.key === 'Enter'){
            sendLogInInfo();
        }
    }

    
    return (
    <div className="Panel-centered">
        <div id="card">
            <div id="card-content">
                <div id="card-title">
                    <h3>Let's Get Started!</h3>
                    <div className="underline-title"></div>
                </div>

                <div className="form">
                    <label for="user-email">Email</label>
                    <input onKeyDown={enterLogin} onChange={(e) => setEmail(e.target.value)} id="user-email" className="form-content" type="email" name="email" autocomplete="on" required/>
                    <div className="form-border2"></div>
                    
                    <div className="space">_</div>
                    
                    <label>Password</label>
                    <input onKeyDown={enterLogin} onChange={(e) => setPassword(e.target.value)} id="user-password" className="form-content" type="password" name="password" required/>
                    <div className="form-border2"></div>


                    <a href="#"> 
                        <legend id="forgot-pass">Forgot password?</legend> 
                    </a>
                    { badCreds ? <div className="error">🚫Something went wrong, please check your data!</div> : null }
                    <div>
                        <button id="submit-btn"
                            onClick={sendLogInInfo}
                            className="btn btn-primary btn-lg btn-block">
                                { badCreds ? <span>Try Again</span>: <span>Log In</span> }
                        </button>
                    
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    )
}
