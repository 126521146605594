import moment from "moment";
import { useState, useEffect } from "react";
import AuthService from "../../services/AuthService";
import CompanyService from "../../services/CompanyService";
import SingleEvent from "./singleEvent/SingleEvent";
import { TbChevronsRight, TbChevronsLeft } from "react-icons/tb";

import { rrulestr } from "rrule";

import "./WeeklySchedule.css"

function WeeklySchedule() {

    const companyService = CompanyService.instance;
    const authService = AuthService.instance;

    const user = authService.getLoggedInUser();

    const [weeklyOffset, setWeeklyOffset] = useState(0)

    const [startOfWeek, setStartOfWeek] = useState(moment().startOf('W').toDate());

    const [groupedEvents, setGroupedEvents] = useState({ 
        Monday: [], 
        Tuesday: [], 
        Wednesday: [], 
        Thursday: [], 
        Friday: [],
        Saturday: [],
        Sunday: [],
    });

    const [headerText, setHeaderText] = useState("");

    useEffect(() => {
        setHeaderText(formatHeader())

        fetchWeeklySchedule();

    }, [weeklyOffset]);

    const fetchWeeklySchedule = async () => {

        let d = moment(startOfWeek).add(weeklyOffset * 7, 'd');

        let dateFrom = d.toDate().toISOString();
        let dateTo = d.clone().add(5, 'd').endOf('d').toDate().toISOString();

        const res = await companyService.getWeeklyScheduleOfCompany(
            user.company._id, dateFrom, dateTo
        )
        console.log(res);
        // Group by day

        let groups = { 
            Monday: [], 
            Tuesday: [], 
            Wednesday: [], 
            Thursday: [], 
            Friday: [],
            Saturday: [],
            Sunday: [],
        }

        for (let schedule of res){

           
            let dayIndex;
            if (schedule.events.length > 0){
                dayIndex = (new Date(
                    schedule.events[0].date
                )).getDay() - 1
            }
            else {
                // rrule lib indexes monday as 0, 
                // but date class as 1
                dayIndex = rrulestr(schedule.rrule).options.byweekday[0]
            }

            groups[Object.keys(groups)[dayIndex]].push(schedule)
        }
        console.log("Setting new events")
        setGroupedEvents(groups)

    }

    const formatHeader = () => {
        switch (weeklyOffset){
            case 0:
                return "This week"
                break;
            case 1:
                return "Next week";
                break;
            case -1:
                return "Last week";
                break;
            default:
                let start = moment(startOfWeek).add(weeklyOffset * 7, 'd').format("DD. MMM");
                let end = moment(start).clone().add(5, 'd').endOf('d').format("DD. MMM");
                // console.log(start, "-", end)
                return `${start} - ${end}`
        }
    }

    return (
        <>
            <div className="row mt-3 Weekly-schedule-header">
                <div className="col-1 offset-2" onClick={() => setWeeklyOffset(weeklyOffset - 1)}><TbChevronsLeft size="2em" /></div>
                <div className="col-6 justify-content-center">
                    <h3>{headerText}</h3>
                </div>
                <div className="col-1" onClick={() => setWeeklyOffset(weeklyOffset + 1)}><TbChevronsRight size="2em" /></div>
            </div>

            <div className="offset-lg-2 col-6 m-auto mt-3">
                {
                    Object.entries(groupedEvents).map(([day, list]) => {
                        return list.map(schedule => {
                            // return (<div key={schedule._id}>{schedule.type}</div>)
                            return <SingleEvent {...schedule } dayFullName={day} key={schedule._id}/>
                        })
                    }).flatMap(x => x)
                }
            </div>
        </> 
    );
}

export default WeeklySchedule;