import config from '../config';

import * as jwt from 'jsonwebtoken';

export default class AuthService {

    static instance = AuthService.instance || new AuthService();

    async login(email, password) {
        try {
            let res = await fetch(`${config.SERVER_URL}/api/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email, password: password })
            });
    
            res = await res.json();
        
            if (res.token){
                localStorage.setItem("token", res.token);
                return true;
            }
            else
                return false;
    
        } catch (err){
            console.log(err);
            return false
        }
    }

    getToken(){
        const token = localStorage.getItem("token");

        return token;
    }

    getLoggedInUser(){

        const token = localStorage.getItem("token");

        if (!token){
            return null;
        }

        return jwt.decode(token);

    }

    userHasRole(role) {
        const user = this.getLoggedInUser();

        return user && user.role === role;
    }

    isLoggedIn() {
        return localStorage.getItem("token");
    }

    logOut(){
        localStorage.removeItem("token");
    }

    async changePassword(newPass) {
        const user = this.getLoggedInUser();
        
        const token = localStorage.getItem("token");

        const url = new URL('/api/auth/change-password', config.SERVER_URL);

        let res = await fetch(url.href, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({ password: newPass})
        });

        if (res.status !== 200)
            throw new Error(res.message ? res.message : "Could not reset password!")

        // res = await res.json();

        return true;

    }

    async resetPassword(newPass, newPassAgain) {
        const user = this.getLoggedInUser();
        
        const token = localStorage.getItem("token");
    

        let res = await fetch(`${config.SERVER_URL}/api/user/${user._id}`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: 'PUT',
            body: JSON.stringify({
                password: newPass,
                password_again: newPassAgain
            })
        });

        let body = await res.json();

        if (res.status !== 200)
            throw new Error(body.message ? body.message : "Could not reset password!")

        return body
    

    }
}