import config from '../config';

export default class DashboardService {

    static instance = DashboardService.instance || new DashboardService();

    async fetchStats(){

        let res = await fetch(`${config.SERVER_URL}/api/dashboard/stats`, {
            method: 'GET',
        });

        res = await res.json();

        return res;

    }

    async fetchTopNClients(n = 3){

        const url = new URL(`/api/dashboard/top-companies`, config.SERVER_URL);

        let res = await fetch(url.href);

        res = await res.json();

        return res;

    }

    async fetchTopNEmployees(n = 3){

        const url = new URL(`/api/dashboard/top-employees`, config.SERVER_URL);

        let res = await fetch(url.href);

        res = await res.json();

        return res;

    }

    async fetchHrsNextNDays(){

        const url = new URL(`/api/dashboard/hours-next-n-days`, config.SERVER_URL);

        let res = await fetch(url.href);

        res = await res.json();

        return res;


    }

}