
import "./ScheduleEntry.css"

function ScheduleEntry({ entry }) {

    // console.log("My props are: ", entry)

    let collapseId = `collapse${entry._id}`

    const constructTitle = () => {
        if (entry.type === 'Single'){
            return (new Date(entry.dateStart)).toLocaleString()
        }
        else {
            return `[${entry.timeInfo.frequency}] ${entry.timeInfo.recurrenceText}`;
        }

    }

    return (
        <div className="accordion Schedule-entry mt-3" id={entry._id}>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#" + collapseId} aria-expanded="false" aria-controls={collapseId}>
                        {constructTitle()}
                    </button>
                </h2>
                <div id={collapseId} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                                { entry.tasks.map(x => (
                                        <div className="Task-list">
                                            <div className="Task-item">
                                                {x}
                                            </div>
                                        </div>
                                ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScheduleEntry;