import { useState } from "react";
import CompanyService from "../../../services/CompanyService";
import AuthService from "../../../services/AuthService";
import GenericModal from "../GenericModal";

function ComplaintModal(props) {

    const companyService = CompanyService.instance;
    const authService = AuthService.instance;

    const {
        close
    } = props;

    const defaultForm ={
        comment: '',
        images: [],
        scheduleEvent: props.scheduleEvent
    };

    const [complaintForm, setComplaintForm] = useState(defaultForm);
    const [fileName, setFileName] = useState(null);
    
    const handleFormInput = (evt) => {
        let files;
        if (evt.target.files) {
            files = Array.from(evt.target.files).map(file => file.name);
        }
        setFileName(files);
        setComplaintForm({
            ...complaintForm,
            [evt.target.name]: evt.target.name === 'images' ? evt.target.files : evt.target.value
        })

    };

    const submitForm = async () => {
        
        if (complaintForm.comment && complaintForm.images.length > 0){

            let base64images = [];

            console.log("IMages:", Object.values(complaintForm.images))

            base64images = await Promise.all(
                Object.values(complaintForm.images).map(f => {
                    return new Promise((resolve, reject) => {
                        let r = new FileReader();
                        r.onload = () => {
                            resolve(r.result);
                        }
                        r.onerror = () => reject();
                        r.readAsDataURL(f);
                    });
                })
            );

            console.log("Submitting:", {
                comment: complaintForm.comment,
                base64images
            })

            try {
                await companyService.fileAComplaint(
                    authService.getLoggedInUser().company._id,
                    props.scheduleEntry,
                    props.scheduleEvent,
                    {
                        comment: complaintForm.comment,
                        base64images
                    }
                );

                setComplaintForm(defaultForm);
                close();

            } catch (err){
                console.log("Could not submit complaint!");
            }
        }

    }
    return (
        <GenericModal {...props}>
                <h3 id="modal-title">File a compaint!</h3>
                <div className="underline-title"></div>
                <div className="space">_</div>
    
                {/* <textarea className="form-control" id="text_area" rows="4" name="comment" value={"Ovo nece submitovati redovno onako kako je trenutno implementirano"}>
                
                </textarea> */}
                
                <textarea 
                    className="form-control" 
                    id="comment" 
                    rows="4" 
                    name="comment" 
                    placeholder="Enter something i guess here..."
                    value={complaintForm.comment}
                    onChange={handleFormInput} />

                {/* <input 
                    type="text" 
                    id="comment"
                    name="comment" 
                    placeholder="Enter something i guess here..."
                    value={complaintForm.comment}
                    onChange={handleFormInput}/> */}


                {/* u sustini ovo je redesign za input, 
                al mi se ne svidja sto se ne vidi koji files su selected...
                videcu sta moze da se uradi 
                
                
                */}
                
                <div className="file-input">
                    <input
                        type="file"
                        id="file"
                        className="file"
                        name="images"
                        accept="image/png, image/jpeg, image/jpg"
                        multiple
                        onChange={handleFormInput}/>

                    <label htmlFor="file" >Select Images</label>
                    <div className="file-name">
                        {
                        fileName ? 
                            fileName.map(name => <span> {name} </span>) : 
                            null
                        }
                    </div>
                </div>
                
                {/* <input 
                    type="file" 
                    name="images"
                    className="image-file"
                    accept="image/png, image/jpeg, image/jpg"
                    multiple
                    onChange={handleFormInput}/> */}
                
                
                <br />

                <div className="space">_</div>
                <div id="button_centering">
                    <button id="modal_button_complaint" onClick={submitForm}>
                        <span>Submit</span>
                    </button>
                </div>
            </GenericModal>
    );
}

export default ComplaintModal;
