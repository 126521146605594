import { useState } from "react";
import appConf from "../../config";
import { Slide } from 'react-slideshow-image';

import "./Complaint.css"

function Complaint({ complaint }) {

    let collapseId = `complaint_collapse${complaint._id}`;
    let date = new Date(complaint.createdAt);

    const hasImages = complaint.images.length > 0

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    
    const incrementIndex = (amount) => {

        let nextIndex = hasImages ? 
            (currentImageIndex + amount) % complaint.images.length
            : 0;

        setCurrentImageIndex(nextIndex);
    }

    return (
        complaint ? 
        <div className="accordion mt-3" id={complaint._id}>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#" + collapseId} aria-expanded="false" aria-controls={collapseId}>
                        {date.toLocaleDateString()}
                    </button>
                </h2>
                <div id={collapseId} className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                        <p><strong>Comment</strong>: {complaint.comment}</p>
                        {
                            hasImages ?
                                <div className="slide-container">
                                        <Slide>
                                            {
                                                complaint.images.map((image, index)=> (
                                                    <div className="each-slide" key={index}>
                                                        <img className="lazy" style={{
                                                            height: 'auto',
                                                            width: 150
                                                        }} src={new URL(image, appConf.SERVER_URL).href} />
                                                    </div> 
                                                    ))
                                            } 
                                        </Slide>
                                </div>
                                //<img className="Complaint-image" src={new URL(complaint.images[currentImageIndex], appConf.SERVER_URL).href}/>  
                                : null
                        }

                    </div>
                </div>
            </div>
        </div>
        : null
    );
}

export default Complaint;