
import React, { useEffect, useState } from 'react';
//npm install react-icons
import { MdSpaceDashboard } from 'react-icons/md';
import { FaUsers, FaBars } from 'react-icons/fa';
import { GiBroom } from 'react-icons/gi';
import { ImProfile } from 'react-icons/im';

import {
    NavLink
} from "react-router-dom"
import AuthService from '../../services/AuthService';

import "./Sidebar.css"

export default function Sidebar() {

    const authService = AuthService.instance;

    // const [user, setUser] = useState({});

    // useEffect(() => {
    //     authService.
    // }, [])

    return (
        <div className="Sidebar">

            <ul className="nav">

                <NavLink 
                    className="Nav-item" 
                    activeClassName="Nav-item--active" 
                    to="/dashboard"><MdSpaceDashboard className="icon-sidebar" size="2em" style={{padding: 5}}/><span className="span-nav">Dashboard</span></NavLink>
                {
                    (authService.userHasRole("admin") ?
                    <NavLink 
                        className="Nav-item" 
                        activeClassName="Nav-item--active" 
                        to="/clients"><FaUsers className="icon-sidebar" size="2em" style={{padding: 5}}/><span className="span-nav">Clients</span></NavLink>
                    : null)
                }
                {
                    (authService.userHasRole("admin") ?
                    <NavLink 
                        className="Nav-item" 
                        activeClassName="Nav-item--active" 
                        to="/employees"><GiBroom className="icon-sidebar" size="2em" style={{padding: 5}}/><span className="span-nav">Employees</span></NavLink>
                : null)
                }

                <NavLink 
                    className="Nav-item" 
                    activeClassName="Nav-item--active" 
                    to="/profile"><ImProfile className="icon-sidebar" size="2em" style={{padding: 5}} /><span className="span-nav">My Profile</span></NavLink>
            </ul>

        </div>
    ) 
}
