import config from "../config";

export default class CompanyService {

    static instance = this.instance || new CompanyService();

    async getCompanies(filter) {
        
        const url = new URL(`${config.SERVER_URL}/api/company`)

        if (filter){
            const searchParams = new URLSearchParams(
                Object.fromEntries(
                    Object.entries(filter).filter(([_, v]) => v)
                )
            )
    
            url.search = searchParams.toString();
    
        }

        let res = await fetch(url);

        res = await res.json();

        return res;
    }

    async getCompanyById(company_id) {

        const url = new URL(`${config.SERVER_URL}/api/company/${company_id}`);

        let res = await fetch(url);

        res = await res.json();

        return res;

    }

    async creteCompany(payload) {
        const url = new URL(`${config.SERVER_URL}/api/company`);

        let res = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        })

    }

    async submitCompanyScheduleEntry(company_id, address, payload) {
        const url = new URL(`/api/company/${company_id}/schedule`, config.SERVER_URL);

        let res = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                ...payload,
                location: address,
            })
        });

        return await res.ok ? res.json() : res.text().then(text => {throw new Error(text)});
    }
    
    async getWeeklyScheduleOfCompany(company_id, dateFrom, dateTo){
        
        const searchParams = new URLSearchParams({ dateFrom, dateTo })

        const url = new URL(`/api/company/${company_id}/schedule?${searchParams.toString()}`, config.SERVER_URL);

        let res = await fetch(url, {
            method: "GET"
        });

        return await res.json();

    }

    async fileAComplaint(company_id, scheduleEntryId, eventId,{ comment, base64images }) {
        const body = {
            comment,
            images: base64images
        };

        const url = new URL(`/api/company/${company_id}/schedule/${scheduleEntryId}/event/${eventId}`, config.SERVER_URL);

        let res = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });

        return await res.json();
    }

}