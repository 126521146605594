import React, { useState, useEffect } from 'react';
import AuthService from '../../services/AuthService';

import './Navbar.css';

// const logo = require("../../../public/aevis")
import logo_med from "../../assets/img/logo_med.png"
import logo_mini from "../../assets/img/logo_mini.png"
import icon_log_out from "../../assets/img/icon_log_out.png"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function Navbar() {

    const authService = AuthService.instance;
    const history = useHistory();
    
    const [showDropdown, setShowDropdown] = useState("none");
    const [currentUser, setCurrentUser] = useState({ first_name: '', last_name: ''});

    useEffect(() => {
        const user = authService.getLoggedInUser();

        if (user){
            setCurrentUser(user);
        }
    }, [authService]);

    const logOut = async () => {

        authService.logOut();

        history.push("/")

    }

    return (
        <div className="Navbar row">
            <div className="col-3 justify-content-center Logo-container">
                <img src={logo_med} id='img_normal' alt="Aevis.de"/>
                <img src={logo_mini} id='img_mini' alt="Aevis.de"/>
            </div>
            <div className="col-9 Navbar-right">
                <div className='Topnav-item'>
                    <div className='Topnav-profile-button'
                        onClick={() => showDropdown === 'none' ? setShowDropdown('block') : setShowDropdown("none")}>
                        {currentUser ? currentUser.first_name.charAt(0) + currentUser.last_name.charAt(0) : ''}
                    </div>
                </div>
            </div>
            <div 
                id="Topnav-menu"
                className='Info-card Topnav-dropdown-menu'
                style={ { display: showDropdown }}>
                <div onClick={logOut}>
                <img src={icon_log_out} className='menu_icon'/><span>Log Out</span>
                    </div>
            </div>
        </div>
    )
}
