import GenericModal from "../GenericModal";
import "./CreateClientModal.css"
import CompanyService from "../../../services/CompanyService";
import { useState } from "react";

function CreateClientModal(props) {

    const companyService = CompanyService.instance;

    const [companyForm, setCompanyForm] = useState({
        name: "",
        address: [""],
        first_name: "",
        last_name: "",
        number: "",
        email: "",
    });

    const handleFormInput = (evt, i) => {
        let newFormValue = {...companyForm};
        i !== undefined ? 
        newFormValue.address[i] = evt.target.value :
        newFormValue = {...newFormValue , [evt.target.name]: evt.target.value}
        setCompanyForm({
            ...newFormValue,
        })

    };
    const addAddressField = () => {
       companyForm.address.push("");
       setCompanyForm({
           ...companyForm
       })
    }

    const removeInputField = (index) => {
        let updatedFormFields = {...companyForm};
        updatedFormFields.address.splice(index, 1);
        setCompanyForm({...updatedFormFields});
    }
    const submitForm = () => {
        companyService.creteCompany(companyForm);
        props.close();
    }
    
    return ( 
        <GenericModal {...props}>
            <h3 id="modal-title1">Create a new client!</h3>
            <div className="underline-title"></div>
            <div className="space">_</div>
            <form>

                <label>Company Name</label>
                <br></br>
                <input type="text" class="field" placeholder="Apple Inc." name="name" value={companyForm.name} onChange={handleFormInput} required/>
                <div className="form-border"></div>
                <div className="space">_</div>

                <label>Company Address</label>
                <br></br>
                
                {   
                    companyForm.address.map((el, index) => (
                    <div key={index} className="relative">
                         {index !== 0 && <div className="remove-btn absolute" title="Remove address field" onClick={() => removeInputField(index)}>x</div>}
                         {index === 0 && <div className="add-btn absolute" title="Add address field" onClick={() => addAddressField()}>+</div>}

                        <input type="text" class="field" placeholder="Rosenstraße 1" name="address" value={el} onChange={(evt) => handleFormInput(evt, index)} required/>
                        <div className="form-border"></div>  
                        <div className="space">_</div>
                    </div>
                    ))
                }
                
                <label>Client's First Name</label>
                <br></br>
                <input type="text" class="field" placeholder="Tim" name="first_name" value={companyForm.first_name} onChange={handleFormInput} required/>
                <div className="form-border"></div>  
                <div className="space">_</div>
                
                <label>Client's Last Name</label>
                <br></br>
                <input type="text" class="field" placeholder="Cook" name="last_name" value={companyForm.last_name} onChange={handleFormInput} required/>
                <div className="form-border"></div>  
                <div className="space">_</div>
                
                <label>Client's Number</label>
                <br></br>
                <input type="text" class="field" placeholder="+49 30 927119794" name="number" value={companyForm.number} onChange={handleFormInput} required/>
                <div className="form-border"></div>  
                <div className="space">_</div>
                
                <label>Client's Email</label>
                <br></br>
                <input type="text" class="field" placeholder="timApple@apple.com" name="email" value={companyForm.email} onChange={handleFormInput} required/>
                <div className="form-border"></div>  
                <div className="space">_</div>
                 
                <br></br>
                <div id="button_centering">
                    <button id="modal_button" 
                    onClick={submitForm} className="btn btn-primary btn-lg btn-block"
                    type="submit"><span>Create!</span></button>
                </div>
            </form>
        </GenericModal>
    );
}

export default CreateClientModal;