
import React, { useState, useEffect } from 'react'
import AddButton from '../../../../components/addbtn/AddButton';
import ScheduleEntry from '../../../../components/schedule/ScheduleEntry';
import CompanyService from '../../../../services/CompanyService';
import ScheduleModal from '../../../../components/modals/schedule/ScheduleModal'
import Complaint from '../../../../components/complaint/Complaint';

// import { toast } from 'react-toastify';

export default function Client({ company_id }) {

    const companyService = CompanyService.instance;
    // const [companyService, setCompanyService] = useState(CompanyService.instance);

    const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [company, setCompany] = useState();

    const [selectedAddress, setSelectedAddress] = useState();

    const closeScheduleModal = () => {
        setShowScheduleModal(false);
        fetchCompany();
    }

    const fetchCompany = async () => {
        try {
            const comp = await companyService.getCompanyById(company_id);

            setCompany(comp);

            setSelectedAddress(comp.address[0]);
        } catch (err){
            // toast.error(err.message);
            console.log(err)
        }

    }
    const onAddressSelection =  (e) => {
        console.log(e.target.value);
        setSelectedAddress(e.target.value)
    }
    useEffect(() => {
        fetchCompany();
    }, [companyService]);

    return (
        <div>

            <div className="row mt-3">
                <div className="col-lg-6 ">
                    <h2>{company?.name}</h2>
                </div>
                <div className="col-lg-6">

                </div>
            </div>

            {/* Company info */}
            <div className="row mt-3">
                <div className="col-12 mt-3 col-lg-6">
                    <div className='Info-card'>
                        <h4>Company Information</h4>
                        <select className='Form-control1' onChange={(e) => onAddressSelection(e)}>
                            {/* <option>{company?.address}</option>
                            <option>{company?.address}</option> */}
                            {
                                company?.address.map(addr => {
                                    return (<option key={addr} value={addr}>{addr}</option>)
                                })
                            }
                        </select>    
                    </div>
                </div>
                <div className="col-12 mt-3 col-lg-6">
                    <div className='Info-card'>
                        <h4>Company Representatives ✉️</h4>
                        <ul>
                            {company?.representatives.map(rep => (<li>{rep.first_name} {rep.last_name} [{rep.email}]</li>))}
                        </ul>
                    </div>
                </div>
            </div>

            {/* Company schedule */}

            <div className="row mt-3">

                <div className="col-12 col-lg-6">
                    <div className="col-lg-12 ">
                        <div className='row'>
                            <h4>Tasks for this Client: </h4>
                        </div>
                        <AddButton callback={() => setShowScheduleModal(true)}/>
                    </div>
                    { !company || Object.keys(company).length === 0 ? null : 
                        company?.schedule_entries.filter(entry => entry.location === selectedAddress).map(entry => {
                            return (<ScheduleEntry entry={entry}/>)
                        }
                    )}
                </div>



             
                {/* E ovo je buggy do jaja, moram da odvojim client complaints od tasks */}
                <div className="col-12 mt-3 col-lg-6 scrollbarSolution">
                    <div className='Info-card'>
                        <h4>Client complaints ⚠️</h4>
                        { company && company?.complaints && company?.complaints.length > 0 ?
                        company.complaints.slice(-3).map(complaint => { 
                            return ( <Complaint complaint={complaint}/> ) 
                        })
                        : <p>No complaints!</p> 
                        }
                    </div>
                </div>
            </div>
            <ScheduleModal 
                company_id={company_id} 
                address={selectedAddress}
                close={() => closeScheduleModal()} 
                show={showScheduleModal}
            />

        </div>
    )
}
