
import { Route, Redirect } from "react-router-dom";
// import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import AuthService from "../services/AuthService";

function RoleProtectedRoute({ children, role, ...rest }) {

    const authService = AuthService.instance;

    return (
        <Route 
            {...rest}
            render={ ( { location } ) => 
                authService.userHasRole(role) ? (children) : 
                    (<Redirect to="/" />)
            }
        />
    );
}

export default RoleProtectedRoute;