
import { useEffect, useState } from "react";
import "./GenericModal.css"
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function GenericModal({children, close, show}) {


    const [isVisible, setIsVisible] = useState(show);

    useEffect(() => {
        console.log('Show is now', show)
        setIsVisible(show)
    }, [show])

    const closeModal = (event) => {
        if (event.target == document.getElementById("modalBgr")){
            close();
        }
    }

    return (
        <>
            {
                  isVisible ? <div className="GenericModal" onClick={(evt) => closeModal(evt)}>
                  <div id="modalBgr" className="ModalMiddle">
                      <div className="ModalContainer col-12">
                          {children}
                      </div>
      
                  </div>
              </div> : null
            }
            <ToastContainer></ToastContainer>
        </>
      
    );
}

export default GenericModal;