import GenericModal from "../GenericModal";

// import CompanyService from "../../../services/CompanyService";
import EmployeeService from "../../../services/EmployeeService";
import { useState } from "react";
// import { toast } from "react-toastify";

function CreateEmployeeModal(props) {

    const {
        close 
    } = props;

    const employeeService = EmployeeService.instance;

    const [employeeForm, setEmployeeForm] = useState({
        first_name: "",
        last_name: "",
        email: "",
    });

    const handleFormInput = (evt) => {
        
        setEmployeeForm({
            ...employeeForm,
            [evt.target.name]: evt.target.value
        })

    };

    const submitForm = async () => {
        try {
            await employeeService.createEmployee(employeeForm);

            close(true);

        } catch (err){
            // toast.error(err.message);
        }
    }

    return ( 
        <GenericModal {...props}>
            <h3 id="modal-title">Create a new employee!</h3>
            <div className="underline-title"></div>
            <div className="space">_</div>
            <form>

                <label>First Name</label>
                <br></br>
                <input type="text" class="field" placeholder="Erik" name="first_name" value={employeeForm.first_name} onChange={handleFormInput} required/>
                <div className="form-border"></div>
                <div className="space">_</div>
                

                <label>Last Name</label>
                <br></br>
                <input type="text" class="field" placeholder="Schmitt" name="last_name" value={employeeForm.last_name} onChange={handleFormInput} required/>
                <div className="form-border"></div>
                <div className="space">_</div>
                
                <label>Phone Number</label>
                <br></br>
                <input type="number" class="field" placeholder="+49 30 927119794" name="number" value={employeeForm.number} onChange={handleFormInput} required/>
                <div className="form-border"></div>
                <div className="space">_</div>

                <label>Email</label>
                <br></br>
                <input type="email" class="field" placeholder="erik_schmitt@gmail.com" name="email" value={employeeForm.email} onChange={handleFormInput} required/>
                <div className="form-border"></div>
                <div className="space">_</div>
                <br></br>

                <div id="button_centering">
                    <button id="modal_button" 
                    onClick={submitForm} className="btn btn-primary btn-lg btn-block"
                    type="submit"><span>Create!</span></button>
                </div>
            </form>
        </GenericModal>
    );
}

export default CreateEmployeeModal;