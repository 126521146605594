
import React, { useState, useEffect } from 'react';
import TopNList from '../../../../components/topNList/TopNList';
import AuthService from '../../../../services/AuthService';
import DashboardService from '../../../../services/DashboardService';

import "./AdminDashboard.css";
import NextNDaysChart from '../../../../components/charts/NextNDaysChart';

export default function AdminDashboard() {

    const dashboardService = DashboardService.instance;
    const authService = AuthService.instance;

    const [stats, setStats] = useState({
        companyCount: 0,
        employeeCount: 0,
        employeeAverageHours: 0,
    });

    const [top3Companies, setTop3Companies] = useState([]);

    const [top3Employees, setTop3Employees] = useState([]);

    const [hrsNextNDays, setHrsNextNDays] = useState({
        hrs: [],
        dates: []
    })

    const [user, setUser] = useState({});

    useEffect(() => setUser(authService.getLoggedInUser()), [authService])
    
    useEffect(async () => {

        (async () => {
            const res = await dashboardService.fetchStats();
            setStats(res);
        })();

        (async () => {
            const res = await dashboardService.fetchTopNClients();
            console.log(res);
            setTop3Companies(res);
        })();

        (async () => {
            const res = await dashboardService.fetchTopNEmployees();
            console.log(res);
            setTop3Employees(res);
        })();


        (async () => {
            const res = await dashboardService.fetchHrsNextNDays();
            console.log(res);
            setHrsNextNDays(res);
        })();

    }, [dashboardService])

    return (
        <div>

            {/* Level 0 */}
            <div className="row mt-3">
                <div className="col-lg-6 Welcome-message">
                    <h2>Hello {user.first_name},</h2>
                    <div className="underline_dashboard"></div>
                </div>
                <div className="col-lg-6">

                </div>
            </div>
            {/* Level 1 */}
            <div className="row mt-3">
                <div className="col-lg-6 ">
                    <div className="Info-card" id="ic_bground">
                        <span id='lmao'></span>
                        {/*<div className="text_centered_img">Let's Get Working! </div> */} 
                    </div>
                </div>
                <div className="space2">&nbsp</div>
                {<br></br>} {<br></br>}
                <div className="col-lg-6">
                    <div className="row">

                        <div className="col-lg-6 mb-2">
                            <div className="Info-card Info-container Info-container-1 p-3" id="dashboard-center">
                                <p>Current # of Clients:</p>
                                <h3>{stats.companyCount}</h3>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                            <div className="Info-card Info-container Info-container-2 p-3" id="dashboard-center">
                                <p>Current # of Employees:</p>
                                <h3>{stats.employeeCount}</h3>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-2">
                            <div className="Info-card Info-container Info-container-3 p-3" id="dashboard-center">
                                <p>Average Hours per Client:</p>
                                <h3>{stats.employeeCount}</h3>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-2">
                            <div className="Info-card Info-container Info-container-4 p-3" id="dashboard-center">
                                <p>Average Hours per Employee:</p>
                                <h3>{stats.employeeCount}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Level 2 */}
            <div className="row mt-3">
                <div className="col-lg-6 ">
                    <div className="Info-card" id="dashboard-center">

                        <TopNList topN={top3Companies} entityName={"Companies"}/>

                    </div>
                </div>

                <div className="col-lg-6 ">
                    <div className="Info-card" id="dashboard-center">

                        <TopNList topN={top3Employees} entityName={"Employees"}/>

                    </div>
                </div>

            </div>

            {/* Level 3 */}

            <div className="row mt-3">
                <div className="col-lg-12 ">
                    <div className="Info-card" id="dashboard-center">

                        <NextNDaysChart apiData={hrsNextNDays}/>

                    </div>
                </div>


            </div>

            {/* <div className="row mt-3">
                <div className="col-lg-6 ">
                    <div className="Info-card" id="dashboard-center">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                    Ut elementum sit amet eros in elementum. 
                    Praesent sit amet aliquam nisi. 
                    Nullam tincidunt enim vel nisl gravida posuere. 
                    In eget semper lacus. 
                    Donec eleifend leo nec risus ullamcorper, a aliquet lacus sagittis. 
                    Pellentesque eget lobortis erat, in lobortis dui. 
                    Fusce ut aliquam libero.
                    </div>
                </div>

                <div className="col-lg-6 ">
                    <div className="row">

                        <div className="col-lg-12 mb-3">
                            <div className="Info-card">
                                Info Block 1
                            </div>
                        </div>
                        <div className="col-lg-12 mb-3">
                            <div className="Info-card">
                                Info Block 2
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space">_</div>
            <div className="space">_</div>
            <div className="space">_</div> */}
        </div>
    )
}
